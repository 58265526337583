



































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Badge from '@/app/ui/components/Badge/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import controller from '@/app/ui/controllers/PickupLocationController'
import {
  EnumStatus,
  PICKUP_LOCATION_BODY_SUCCESS_ACTIVATE,
  PICKUP_LOCATION_BODY_SUCCESS_DEACTIVATE,
  PICKUP_LOCATION_TITLE_SUCCESS_ACTIVATE,
  PICKUP_LOCATION_TITLE_SUCCESS_DEACTIVATE,
} from '@/app/infrastructures/misc/Constants'
import { EventBusConstants, Utils } from '@/app/infrastructures/misc'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import dayjs from 'dayjs'
import ModalConfirmation from '../components/PickupLocationModal/ModalConfirmation/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'

@Component({
  components: {
    Badge,
    Button,
    LoadingOverlay,
    ModalConfirmation,
    Modal,
  },
})
export default class DetailLocation extends Vue {
  controller = controller
  modalConfirmation = {
    visible: false,
    status: '',
  }
  modalSuccess = {
    visible: false,
    title: '',
    body: '',
  }
  isSuperAdmin = Utils.isSuperAdmin()

  created(): void {
    controller.getPickupLocation(this.$route.params.id)
  }

  @Watch('controller.statusActiveDeactivateDetail')
  onStatusActiveDeactivateDetail(status: string): void {
    if (status !== '' && status === EventBusConstants.ACTIVATE_DEACTIVATE_PICKUP_LOCATION_DETAIL_SUCCESS) {
      controller.getPickupLocation(this.$route.params.id)
      this.modalConfirmation = {
        visible: false,
        status: '',
      }
      this.modalSuccess.visible = true
    }

    controller.setStatusActiveDeactivateDetail('')
  }

  private isActive(): boolean {
    return this.controller.pickupLocationDetail.status?.toUpperCase() === EnumStatus.ACTIVE
  }

  private getType(): string {
    return this.isActive() ? 'success' : 'error'
  }

  private getStatus(): string {
    const status = String(controller.pickupLocationDetail.status)
    return Utils.toCapitalize(status?.replace(/_/g, ' '))
  }

  private getDate(): string {
    return dayjs(controller.pickupLocationDetail.updatedAt).format(
      'DD MMMM YYYY HH:mm WIB'
    )
  }

  private showModalConfirmation(bool = false): void {
    this.modalConfirmation = {
      visible: bool,
      status: !this.isActive() ? 'active' : 'not_active',
    }
  }

  private handleEmitSubmit(): void {
    if (this.isSuperAdmin) {
      controller.activateOrDeactivatePickupLocation({
        isActive: !this.isActive(),
        routeIDs: [parseInt(this.$route.params.id)],
      })
      this.modalSuccess = {
        visible: false,
        title: !this.isActive()
          ? PICKUP_LOCATION_TITLE_SUCCESS_ACTIVATE
          : PICKUP_LOCATION_TITLE_SUCCESS_DEACTIVATE,
        body: !this.isActive()
          ? PICKUP_LOCATION_BODY_SUCCESS_ACTIVATE
          : PICKUP_LOCATION_BODY_SUCCESS_DEACTIVATE,
      }
    }
  }

  private handleSuccess(): void {
    this.modalSuccess = {
      visible: false,
      title: '',
      body: '',
    }
  }

  beforeDestroy(): void {
    controller.setStatusActiveDeactivateDetail('')
  }
}
